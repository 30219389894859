import { Injectable, signal } from '@angular/core';
import { CourseSectionI } from './interfaces/course-section.interface';
import { Plo, Clo } from './interfaces/aol-report-course-section.interface';

@Injectable({
  providedIn: 'root',
})
export class CourseSectionsService {
  selectedCourseSection = signal<CourseSectionI | null>(null);

  selectedPlo = signal<Plo | null>(null);

  selectedClo = signal<Clo | null>(null);
}
